
.App{
  background-color: rgb(112, 73, 186);
  min-height: 100vh;
  width: 100%;
  color: white;
  transition: background-color 0.5s ease-in-out 0s;
  box-sizing: border-box;
  padding: 0px;
}

.timer {
  margin-top: calc(100vh/200);
  text-align: center;
  color:white;
}

.timer .working {
  color: rgb(255, 255, 255);
}

.timer .resting {
  color: rgb(255, 255, 255);
}

.controls {
  text-align: center;
}

.start-button {
  background-color: #370fe907;
  color: white;
  border: 2px solid white;
  padding: calc(100vh/60);
  margin: calc(100vh/90);
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: calc(100vh/100);
  font-size: calc(100vh/60);
}

.reset-button:active, .btnPause:active, .start-button:active, .configBtn:active {
  color: black;
  background: #B4CED9;
  transform: translateY(4px);
  box-shadow: 0px -4px 0 0px #F2BC1B;
}

.btnPause:hover, .start-button:hover, .configBtn:hover {
  background-color: #B4CED9;
  color: black;
}

.btnPause, .configBtn, .reset-button, .start-button {
  background-color: #370fe907;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0.2em;
  padding: 0.6rem;
  transition: background-color .25s;
  width: 10rem;
  max-width: 90vw;   
  font-size: 1rem;
}

.configBtn{
  background-color: #370fe907;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.6rem;
  transition: background-color 0.25s;
  display: inline-block;
  margin: 0.2em;
}

.soundBtn{
  background-color: #370fe907;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.6rem;
  transition: background-color 0.25s;
  display: inline-block;
  margin: 0.2em;
}


.reset-button:hover {
  background-color: #B4CED9;
}

.textIcon{
  margin-right: calc(100vh/90);
  color: white;
}

.btnPause:visited, .configBtn:visited, .reset-button:visited, .start-button:visited {
  background-color: #370fe907;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0.2em;
  padding: 0.6rem;
  transition: background-color .25s;
  width: 10rem;
  max-width: 90vw;   
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Kdam Thmor Pro', sans-serif;
  background-color: rgb(12 15 42);
  overflow-y: scroll;
}

.tabata-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.progressbar-background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  transition: all 0.3s linear;
  z-index: 1;
}


.tabata-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.progressbar-background.working {
  background-color: rgb(56, 138, 56);  /* Color de trabajo */
}

.progressbar-background.resting {
  background-color: rgb(138, 56, 56);  /* Color de descanso */
}



.config-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;  
  height: 430px;
  transform: translate(-50%, -50%);  
  z-index: 3; 
  background-color: rgba(0, 0, 0, 0.932);  
  border-radius: 10px;  
  padding: 20px; 
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); 
}

.lbl {
    font-size: 2rem;
    justify-self: baseline;
    margin: 0.2rem;
    position: relative;
    color: #fff;
    padding: 0.1rem;
    align-self: center;
}

.inputConfig {
    border: none;
    border-radius: 5px;
    float: left;
    font-size: 1.3rem;
    height: 2rem;
    margin: 0.5rem;
    width: 4rem;
    text-align: center;
}

.tabata-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 2;
    overflow: auto;
}

.config-container {
    align-items: center;
    background-color: rgba(0,0,0,.932);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0,0,0,.5);
    display: grid;
    height: 28rem;
    justify-content: center;
    left: 50%;
    padding: 2rem 1rem 2rem 1rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 20rem;
    z-index: 3;
    justify-items: center;
}

.inputConfig{
  width: calc(100vh/10);
  height: calc(100vh/30);
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: calc(100vh/40);
  margin: calc(100vh/90);
  float: left;
}


label {
  margin: calc(100vh/90) 0;
  position: relative;
  justify-self: center;
  text-align: center;
  font-size: calc(100vh/30);
}

.segs {
  color: #fff;
  font-size: 12em;
  margin: -2rem 1px 0 1px;
  padding: 0.1rem;
  text-shadow: 0.3rem 0.3rem black;
}

.fullscreen-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.countdown {
  background-color: rgb(224, 224, 44);
}

.config-container.open {
  margin-top: -50vh; /* Puedes ajustar este valor según lo que necesites */
}

.referidos{
  color: rgb(6, 0, 32);
  background-color: #B4CED9;
  height: 100%;
  width: 100%;
  font-size: calc(100vh/60);
  text-align: center;
  padding: 1rem;

}

.referidosRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.card{
  border-radius: 10px;
  padding: 1rem;
  background-color: #b3a2ff6b;
  color: white;
  margin: 2px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

}
.card:hover{
  background-color: #b3a2ff;
  color: white;
  margin: 2px;
}

.seo{
  background-color: rgb(216, 223, 228);
  color: rgb(80, 80, 80);
  text-align: center;
  padding: 2rem;
  justify-content: center;
  font-weight: 100;
  font-size: 14px;
}
.footer{
  color: aliceblue;
  justify-content: center;
  text-align: center;
  padding: 0.4rem;
  font-size: 12px;
}

/* Ocultar scrollbar */
body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

body::-webkit-scrollbar-thumb {
    background: transparent;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}